<template>
  <div class="equipmentList_wrap">
    <!-- <div class="equipmentList_title">设备列表</div> -->
    <div class="equipmentList_main">
      <div class="equipmentList_top_section">
        <div class="data_display_section">
          <div class="title">全部设备</div>
          <div class="content">{{ equipmentListCountData.allCount }}个</div>
        </div>
        <div class="data_display_split_line"></div>
        <div class="data_display_section">
          <div class="title">已接入</div>
          <div class="content">
            {{ equipmentListCountData.connectedCount }}个
          </div>
        </div>
        <div class="data_display_split_line"></div>
        <div class="data_display_section">
          <div class="title">未接入</div>
          <div class="content">
            {{ equipmentListCountData.notConnectedCount }}个
          </div>
        </div>
      </div>
      <div class="basic_equipmentList">
        <div class="basic_equipmentList_wrap">
          <div class="basic_equipmentList_head">
            <div class="basic_equipmentList_head_left">基本列表</div>
            <div class="basic_equipmentList_head_right">
              <el-radio-group
                @change="getEquipmentListInfo"
                v-model="equipmentSearchData.statusStr"
                class="equipment_status_select"
              >
                <el-radio-button label="全部"></el-radio-button>
                <el-radio-button label="已接入"></el-radio-button>
                <el-radio-button label="未接入"></el-radio-button>
              </el-radio-group>
              <el-input
                suffix-icon="el-icon-search"
                placeholder="请输入关键字"
                v-model="equipmentSearchData.name"
                @input="getEquipmentListInfo"
                clearable
                class="equipment_name_input"
              >
              </el-input>
              <el-select
                v-model="equipmentSearchData.type"
                min-width="35%"
                clearable
                @change="getEquipmentListInfo"
                placeholder="请选择"
                class="equipment_type_option"
              >
                <el-option
                  v-for="item in equipmentTypeList"
                  :key="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="basic_equipmentList_wrap_main">
            <el-table
              :data="EquipmentList"
              stripe
              :row-style="{ height: '75px' }"
              style="width: 100%"
              :show-header="false"
            >
              <el-table-column label="Operate" min-width="30%">
                <template #default="{ row }">
                  <div class="scope">
                    <div class="img">
                      <img :src="`${baseURL}${row.pic}`" alt="" />
                    </div>
                    <div class="right">
                      <div class="name">{{ row.name }}</div>
                      <div class="func">{{ row.func }}</div>
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="Operate" min-width="25%">
                <template #default="{ row }">
                  <div class="area">设备路径</div>
                  <div class="area">
                    {{ row.area }}/{{ row.type }}/{{ row.func }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Operate" min-width="20%">
                <template #default="{ row }">
                  <div class="time">接入时间</div>
                  <div class="time">{{ row.createTime }}</div>
                </template>
              </el-table-column>
              <el-table-column label="Operate" min-width="10%">
                <template #default="{ row }">
                  <div class="status">状态</div>
                  <div class="status">
                    {{ row.statusStr }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Operate" align="center" min-width="15%">
                <template #default="{ row }">
                  <div class="btns">
                    <div class="btn" @click="goEquipmentDetail(row.id)">
                      查看
                    </div>
                    <div class="line"></div>
                    <div class="btn" @click="openEditEquipmentDialog(row.id)">
                      编辑
                    </div>
                    <div class="line"></div>
                    <div class="btn" @click="delectEquipment(row)">删除</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination_row">
              <div class="pagination_row_left">共{{ totalEquipmentNum }}条</div>
              <div class="pagination_row_right">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="totalEquipmentNum"
                  :page-size="PAGESIZE"
                  v-model:current-page="equipmentSearchData.pageNum"
                  @current-change="getEquipmentListInfo"
                  class="pagination_item"
                >
                </el-pagination>
                <div class="pagination_row_input_wrap">
                  跳转到<el-input-number
                    class="pagination_row_input_box"
                    style="height: 30px"
                    v-model="pageInputNum"
                    :step="1"
                    :precision="0"
                    :min="1"
                    :max="maxPageNum"
                    size="small"
                  />页
                </div>
                <el-button
                  style="height: 30px"
                  class="pagination_row_btn"
                  size="small"
                  @click="handleJumpPage"
                  >确定</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 编辑设备弹窗窗口 -->
    <el-dialog
      v-model="editEquipmentDialogVisiable"
      title="编辑设备"
      width="50%"
      class="edit_equipment_dialog_box"
    >
      <!-- :before-close="handleClose" -->
      <div class="dialog_main">
        <el-form
          :model="editEquipmentData"
          label-width="80px"
          label-position="left"
        >
          <el-form-item label="设备名称" prop="name">
            <el-input
              v-model="editEquipmentData.name"
              size="default"
              style="width: 50%"
            ></el-input>
          </el-form-item>

          <el-form-item label="设备图片">
            <div class="equipment_img_wrap">
              <img
                class="old_equipment_img"
                :src="imgShowSrc"
                alt="设备图片"
                @click="uploadPicture(baseURL + editEquipmentData.pic)"
              />
              <el-button
                @click="uploadPicture(baseURL + editEquipmentData.pic)"
                type="primary"
              >
                更换图片
              </el-button>
              <!-- <el-upload
                class="upload-product-photo"
                v-model:file-list="editEquipmentData.imgFileList"
                method="post"
                ref="uploadRef"
                list-type="picture-card"
                :before-upload="handleBeforeUpload"
                :action="actionAddress"
                :headers="uploadHeaders"
                :auto-upload="true"
                :limit="1"
                :on-exceed="handleExceed"
                :on-success="handleSuccess"
                :on-error="handleError"
              >
                <el-icon><Plus /></el-icon>

                <template #file="{ file }">
                  <div>
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <el-icon><zoom-in /></el-icon>
                      </span>
                      <span
                        class="el-upload-list__item-delete"
                        @click="
                          handleRemove(file, editEquipmentData.imgFileList)
                        "
                      >
                        <el-icon><Delete /></el-icon>
                      </span>
                    </span>
                  </div>
                </template>
              </el-upload> -->
            </div>
          </el-form-item>
          <el-form-item label="设备说明" prop="description">
            <el-input
              class="description_input"
              v-model="editEquipmentData.description"
              placeholder="请输入设备说明"
              type="textarea"
              style="width: 50%"
            />
          </el-form-item>
          <el-form-item label="设备区域" prop="area">
            <el-cascader
              ref="cascaderRef"
              :options="regionDataRef"
              v-model="selectedOptions"
              style="width: 50%"
              size="default"
              @change="changeSelectedOptions"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="设备类型" prop="type">
            <el-select
              v-model="editEquipmentData.type"
              size="default"
              style="width: 50%"
            >
              <!-- 设备分类列表（弹窗部分去除全部) -->
              <el-option
                v-for="equipmentType in equipmentTypeList.filter(
                  (item) => item !== '全部'
                )"
                :key="equipmentType"
                :label="equipmentType"
                :value="equipmentType"
                size="default"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="设备功能" prop="func">
            <el-select
              v-model="editEquipmentData.func"
              size="default"
              style="width: 50%"
            >
              <el-option
                v-for="equipmentFunc in equipmentFuncList"
                :key="equipmentFunc"
                :label="equipmentFunc"
                :value="equipmentFunc"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog_footer">
          <!-- <el-button @click="handleClose">取消</el-button> -->
          <el-button @click="handleCancle" size="default">取消</el-button>
          <el-button type="primary" @click="editEquipment" size="default"
            >提交</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- <el-dialog v-model="imgPreviewDialogVisible" center>
      <img
        class="preview_img"
        w-full
        :src="dialogImageUrl"
        alt="Preview Image"
      />
    </el-dialog> -->
    <!-- 剪裁组件弹窗 -->
    <el-dialog title="裁剪图片" v-model="cropperModel" width="800px" center>
      <CropperImage
        :Name="cropperName"
        @uploadImgSuccess="handleUploadSuccess"
        ref="child"
      ></CropperImage>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ElMessage,
  ElMessageBox
  // genFileId
} from 'element-plus';
// import { Delete, Plus, ZoomIn } from '@element-plus/icons-vue';
import { ref, reactive, computed, onMounted } from 'vue';
// import { useI18n } from "vue-i18n";
import {
  getEquipmentList,
  getEquipmentListType,
  deleteEquipmentById,
  getEquipmentDetail,
  putEditEquipment,
  getEquipmentListCount
} from '@/api/equipment.js';
import { useRouter } from 'vue-router';
import { setItem } from '@/utils/storage';
import { resolve } from 'path';
import { regionData } from 'element-china-area-data';
import { CropperImage } from './components';

// const baseURL = 'https://iottest.blueasiainfo.com:29202';
const baseURL = process.env.VUE_APP_BASE_API;

const PAGESIZE = 10; // 一页最多显示的数量

// 获取设备列表数量数据
function getEquipmentListCountApi() {
  getEquipmentListCount().then((res) => {
    const { code, data, msg } = res;
    if (code === 200) {
      console.log('getEquipmentListCountApi data', data);
      for (const key of Object.keys(equipmentListCountData)) {
        equipmentListCountData[key] = data[key];
      }
    } else {
      ElMessage.error(msg);
    }
  });
}
// 设备列表数量数据
const equipmentListCountData = reactive({
  allCount: 0,
  connectedCount: 0,
  notConnectedCount: 0
});

const regionDataRef = ref(regionData);
console.log('regionData=>', regionData);

// 输入框的页数
const pageInputNum = ref(1);
// 最大的页数
const maxPageNum = ref(1);

// 点击页数跳转的 确认 按钮
function handleJumpPage() {
  equipmentSearchData.pageNum = pageInputNum.value;
  console.log('equipmentSearchData.pageNum', equipmentSearchData.pageNum);
  getEquipmentListInfo();
}

// 设备列表查询对应的数据
const equipmentSearchData = reactive({
  area: '', // 设备区域
  areaCode: '', // 设备区域代码
  code: '', // 设备编号
  description: '', // 设备描述
  emergencyContactInfo: '', // 管理员信息
  func: '', // 设备功能
  id: -1, // 设备id
  isIgnoreWarn: -1, // 是否忽略报警 0：否；1：是
  isTop: null, // 是否置顶
  name: '', // 设备名称
  nickname: '', // 用户昵称
  pageNum: 1, // 页码
  pageSize: PAGESIZE, // 每页条数
  pic: '', // 设备图片
  status: 0, // 设备状态 1 已接入 3 未接入
  statusStr: '全部', // 设备状态字符串
  type: '', // 设备类型
  userId: -1 // 用户id
});

// 设备展示列表
const EquipmentList = ref();
// 数据库中符合要求的数据的数量
const totalEquipmentNum = ref(0);

// 获取设备列表Api
function getEquipmentListInfoApi(data) {
  getEquipmentList(data).then((res) => {
    const { data } = res;
    console.log(data);
    const { list, total, size } = data;
    EquipmentList.value = list;
    totalEquipmentNum.value = total;
    console.log('total:', total);
    console.log('size:', size);
    maxPageNum.value = Math.ceil(total / size);
  });
}
// 获取设备列表
function getEquipmentListInfo() {
  const { statusStr, name, type, pageNum, pageSize } = equipmentSearchData;
  const params = {
    name,
    type,
    pageNum,
    pageSize
  };
  let status;
  if (statusStr === '已接入') {
    status = 1;
  } else if (statusStr === '未接入') {
    status = 3;
  }
  status && (params.status = status);
  getEquipmentListInfoApi(params);
}

// 设备分类列表
const equipmentTypeList = ref();
// 获取设备分类列表信息Api
function getEquipmentTypeListApi() {
  getEquipmentListType().then((res) => {
    const { data } = res;
    equipmentTypeList.value = data;
  });
}

// 设备功能列表
const equipmentFuncList = ['实时检测', '延时检测'];

// 跳转到设备详情
const router = useRouter();
function goEquipmentDetail(id) {
  setItem('equipmentId', id);
  router.push({ path: '/equipment/equipmentDetail', query: { id } });
}

// 删除当前设备
function delectEquipment(rowData) {
  console.log(rowData);
  const { id, name } = rowData;
  ElMessageBox.confirm(`是否要删除 ${name}?`, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then((response) => {
    deleteEquipmentById(id).then((res) => {
      const { data, code } = res;
      console.log('删除成功' + data);
      if (code === 200) {
        ElMessage({
          type: 'success',
          message: '删除成功!'
        });
        // 删除的是最后一个设备,并且现在不是第一页
        if (
          EquipmentList.value?.length === 1 &&
          equipmentSearchData.pageNum !== 1
        ) {
          equipmentSearchData.pageNum -= 1;
        }
        getEquipmentListInfo();
        getEquipmentListCountApi();
      }
      resolve(response);
    });
  });
  // .catch(() => {
  //   ElMessage({
  //     type: 'info',
  //     message: 'Delete canceled'
  //   });
  // });
}

// 编辑设备是否显示
const editEquipmentDialogVisiable = ref(false);
// let editFLag = false; // 是否编辑过设备内容(简单判断一下,当输入内容改变的时候设置为true)
const editEquipmentData = reactive({
  area: '', // 设备区域
  areaCode: '', // 设备区域代码
  code: '', // 设备编号
  description: '', // 设备描述
  emergencyContactInfo: '', // 管理员信息
  func: '', // 设备功能 实时监测
  id: 0, // 设备id
  isIgnoreWarn: 0, // 是否忽略报警 0：否；1：是
  isTop: true, // 是否置顶
  name: '', // 设备名称
  nickname: '', // 用户昵称
  pic: '', // 设备图片
  status: 0, // 设备状态
  type: '', // 设备类型 电源监测
  userId: 0 // 用户id
  // imgFileList: [], // 上传照片用的数组
  // imgUrl: '' // 服务器返回的url值
});

// 关闭弹窗
// function handleClose(done) {
//   console.log('关闭弹窗');
//   if (!editFLag) {
//     console.log(done);
//     typeof done === 'function' && done();
//     editEquipmentDialogVisiable.value = false;
//     return;
//   }
//   ElMessageBox.confirm('您确定放弃更改设备关闭弹窗?', '提示', {
//     confirmButtonText: '确认',
//     cancelButtonText: '取消',
//     type: 'warning'
//   }).then(() => {
//     editEquipmentDialogVisiable.value = false;
//     editFLag = false;
//     typeof done === 'function' && done();
//   });
// }
// 点击取消按钮
function handleCancle() {
  editEquipmentDialogVisiable.value = false;
}

// 打开编辑设备弹窗
function openEditEquipmentDialog(id) {
  editEquipmentDialogVisiable.value = true;
  editEquipmentData.id = id;
  getEquipmentDetail(id).then((res) => {
    const { code, data, msg } = res;
    if (code === 200) {
      console.log('openEditEquipmentDialog', data);
      for (const key of Object.keys(editEquipmentData)) {
        editEquipmentData[key] = data[key];
      }
      updateSelectOption(data.areaCode);
    } else {
      ElMessage.error(msg);
    }
  });
}

// 编辑设备
function editEquipment() {
  const {
    code,
    description,
    emergencyContactInfo,
    func,
    id,
    isIgnoreWarn,
    isTop,
    name,
    nickname,
    pic,
    status,
    type,
    userId
  } = editEquipmentData;

  // 根据 areCode 得到对应的 省 市 区
  const rawAreaArr = [];
  const AreaArr = getAreaArr(selectedOptions);
  const newArea = AreaArr.join('');
  // 为areaCode 补 0
  rawAreaArr.push(selectedOptions[0] + '0000');
  rawAreaArr.push(selectedOptions[1] + '00');
  rawAreaArr.push(selectedOptions[2]);
  const newAreaCode = rawAreaArr.join(',');
  const params = {
    area: newArea,
    areaCode: newAreaCode,
    code,
    description,
    emergencyContactInfo,
    func,
    id,
    isIgnoreWarn,
    isTop,
    name,
    nickname,
    pic: imgFileName.value || pic,
    status,
    type,
    userId
  };
  console.log(params);

  putEditEquipment(params).then((res) => {
    console.log(res);
    const { code, msg } = res;
    if (code < 300 && code >= 200) {
      ElMessage.success({
        message: '修改成功!'
      });
      editEquipmentDialogVisiable.value = false;
      getEquipmentListInfo();
    } else {
      ElMessage.error({
        message: `修改失败!${msg}`
      });
    }
  });
}

// 修改选中的设备区域数组
function changeSelectedOptions(value) {
  console.log(value);
  for (let i = 0; i < 3; i++) {
    selectedOptions[i] = value[i];
  }
}

// 级联选择器Ref
const cascaderRef = ref(null);
// 选择设备区域数组 ["11","1101","110101"]
const selectedOptions = reactive(['11', '1101', '110101']);
// 更新选择设备区域数组
function updateSelectOption(areaCode) {
  const rawAreaArr = areaCode.split(',');
  rawAreaArr[0] = rawAreaArr[0].slice(0, 2);
  rawAreaArr[1] = rawAreaArr[1].slice(0, 4);
  editEquipmentData.areaCode = rawAreaArr.join(',');
  for (let i = 0; i < 3; i++) {
    selectedOptions[i] = rawAreaArr[i];
  }
  console.log(cascaderRef.value);
  // const areaArr = getAreaArr(rawAreaArr);
  // const areaStr = areaArr.join(' / ');
  // console.log('areaStr', areaStr);
  // console.log('cascaderRef.value.inputValue', cascaderRef.value.inputValue);
}

// 获取 Area 值 数组 省 市 区
function getAreaArr(rawAreaArr) {
  const areaArr = [];
  const firstLevelIndex = regionData.findIndex(
    (item) => item.value === rawAreaArr[0]
  );
  const firstLevelData = regionData[firstLevelIndex];
  areaArr.push(firstLevelData.label);
  const secodeLevelIndex = firstLevelData.children.findIndex(
    (item) => item.value === rawAreaArr[1]
  );
  const secondLevelData = firstLevelData.children[secodeLevelIndex];
  areaArr.push(secondLevelData.label);
  const thirdLevelIndex = secondLevelData.children.findIndex(
    (item) => item.value === rawAreaArr[2]
  );
  const thirdLevelData = secondLevelData.children[thirdLevelIndex];
  areaArr.push(thirdLevelData.label);
  console.log('areaArr', areaArr);
  if (areaArr[1] === '市辖区') {
    console.log('市辖区');
    areaArr[1] = areaArr[0];
  }
  return areaArr;
}

// 判断是否为图片类型
// function isImageType(str) {
//   // toLowerCase() 将字符串转换为小写，返回一个新的字符串
//   // bmp, gif, jpg, jpeg, png
//   return (
//     ['image/png', 'image/jpeg', 'image/gif', 'image/bmp'].indexOf(
//       str.toLowerCase()
//     ) !== -1
//   );
// }

// 上传地址
// const actionAddress = process.env.VUE_APP_BASE_API + '/api/common/upload';

// 图片弹窗信息
// const dialogImageUrl = ref(''); // 弹窗预览货物图片url
// const imgPreviewDialogVisible = ref(false); // 弹窗预览是否显示

// 上传模块
// const uploadRef = ref(null);
// 图片文件上传列表数据
// const uploadHeaders = {
//   'X-YAuth-Token': localStorage.getItem('token')
// };

// 处理上传前验证操作
// function handleBeforeUpload(rawFile) {
//   // TODO:这里可能要进行图片截取 成 宽900 : 高600格式的图片
//   // console.log(rawFile.type);
//   if (!isImageType(rawFile.type)) {
//     // 图片格式不对不给上传
//     ElMessage.error(
//       `图片不能为 ${rawFile.type}格式，可以为 png/jpg/jpeg/webp格式！`
//     );
//     return false;
//   } else if (rawFile.size / 1024 / 1024 > 10) {
//     // 大于 10MB
//     // 图片大小 大于 10MB 不给上传
//     ElMessage.error('图片不能大于 10MB！');
//     return false;
//   }
//   return true;
// }

// // 处理上传失败后执行
// function handleError() {
//   ElMessage({
//     type: 'error',
//     message: '上传图片失败，请重新上传试试！'
//   });
// }
// // 处理上传成功后执行
// // response, uploadFile, uploadFiles
// function handleSuccess(response, uploadFile, uploadFiles) {
//   if (response.code >= 200 && response.code < 300) {
//     ElMessage({
//       type: 'success',
//       message: '上传图片成功！'
//     });
//     console.log(response, uploadFile, uploadFiles);
//     // 更新当前的url值
//     uploadFile?.response?.data?.fileName &&
//       (editEquipmentData.imgUrl = uploadFile.response.data.fileName);
//   } else {
//     // 上传失败
//     ElMessage({
//       type: 'error',
//       message: '上传图片失败，请重新上传试试！'
//     });
//     // 回退操作
//     editEquipmentData.imgUrl = '';
//     const length = editEquipmentData.imgFileList?.length;
//     if (length) editEquipmentData.imgFileList.splice(0, length);
//     const thisRef = uploadRef.value;
//     thisRef.clearFiles();
//   }
// }

// // 超出规定范围后执行的方法
// function handleExceed(files) {
//   ElMessageBox.confirm(
//     '您已经上传了一张图片了，是否要覆盖上一张图片？',
//     '提示：',
//     {
//       confirmButtonText: '确定覆盖',
//       cancelButtonText: '取消',
//       type: 'warning'
//     }
//   ).then(() => {
//     const thisRef = uploadRef.value;
//     thisRef.clearFiles();
//     const file = files[0];
//     file.uid = genFileId();
//     thisRef.handleStart(file);
//     thisRef.submit();
//   });
// }

// // 点击移除按钮后触发的函数
// function handleRemove(uploadFile, uploadFiles) {
//   // console.log(uploadFile);
//   // upload.value?.handleRemove(uploadFile);
//   // console.log(uploadFiles);
//   uploadFiles.splice(0, 1);
// }

// // 点击放大镜预览按钮后触发的函数
// function handlePictureCardPreview(file) {
//   dialogImageUrl.value = file.url;
//   imgPreviewDialogVisible.value = true;
// }

// // 是否显示旧的设备图片
// const oldEquipmentVisiable = computed(() => {
//   return (
//     !editEquipmentData.imgFileList ||
//     editEquipmentData.imgFileList?.length === 0
//   );
// });

// <!-- :src="`${baseURL}${editEquipmentData.pic}`" -->
const imgShowSrc = computed(() => {
  return (
    baseURL + (imgFileName.value ? imgFileName.value : editEquipmentData.pic)
  );
});

// 是否显示 裁剪图片 弹框
const cropperModel = ref(false);
// 默认图片的路径
const cropperName = ref(''); // 默认图片参数

// 成功上传后的回调地址 fileName
const imgFileName = ref('');

// 在此处调文件上传的接口
function handleUploadSuccess(data) {
  console.log('handleUploadSuccess data', data);

  // 保持它的fileName
  imgFileName.value = data.fileName;
  cropperModel.value = false;
}

// 上传图片
function uploadPicture(name) {
  cropperModel.value = true;
  cropperName.value = name;
}

// 一进来初始化操作
function init() {
  // 获取设备列表数量
  getEquipmentListCountApi();
  // 进来先获取设备列表
  getEquipmentListInfo();
  // 进来先调用获取设备分类列表信息Api
  getEquipmentTypeListApi();
}

onMounted(() => {
  init();
});
</script>

<style lang="scss" scoped>
.equipmentList_wrap {
  background-color: #fff;

  // .equipmentList_title {
  //   height: 58px;
  //   line-height: 58px;
  //   padding-left: 1rem;
  //   font-size: 20px;
  // }
  .equipmentList_main {
    background-color: #f9f9f9;
    padding: 14px;

    .equipmentList_top_section {
      height: 88px;
      background-color: #fff;
      display: flex;

      .data_display_split_line {
        width: 1px;
        // height: 100%;
        margin: 1rem 0;
        border-left: 1px solid #e8e8e8;
      }

      .data_display_section {
        flex: 1;
        padding: 1rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          color: rgba(0, 0, 0, 0.25);
          display: block;
        }

        .content {
          font-size: 1.4rem;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }

    .basic_equipmentList {
      margin-top: 14px;
      background-color: #fff;

      .basic_equipmentList_wrap {
        // height: 100%;
        padding: 14px;

        .basic_equipmentList_head {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .basic_equipmentList_head_left {
            font-size: 18px;
          }

          .basic_equipmentList_head_right {
            display: flex;
            height: 40px;
            align-items: center;

            .equipment_status_select {
              flex: 1.2;
              margin-right: 15px;

              //   :deep(.el-radio-button__inner) {
              //     height: 35px;
              //     line-height: 35px;
              //   }
            }

            .equipment_name_input {
              height: 32px;
              margin-right: 15px;
              :deep(.el-input__inner) {
                height: 32px;
                // line-height: 10px;
              }
            }

            .equipment_type_option {
              height: 32px;

              :deep(.el-input) {
                height: 32px;
                // line-height: 10px;
              }
            }

            .equipment_type_option,
            .equipment_name_input {
              flex: 1.2;
            }
          }
        }

        .basic_equipmentList_wrap_main {
          margin-top: 10px;
          :deep(.scope) {
            display: flex;

            .img {
              width: 75px;
              height: 50px;
              margin-right: 10px;
              border-radius: 2px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 2px;
              }
            }

            .name {
              color: rgba(0, 0, 0, 0.95);
              font-size: 14px;
            }
          }

          :deep(.btns) {
            display: flex;

            .btn {
              margin: 0 8px;
              color: #1890ff;
              cursor: pointer;
            }

            .line {
              margin-top: 2px;
              width: 1px;
              height: 16px;
              border-left: 1px solid #dcdfe6;
            }
          }

          .pagination_row {
            margin-top: 10px;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .pagination_row_left {
              font-size: 13px;
              color: #606266;
            }

            .pagination_row_right {
              display: flex;
              font-size: 13px;
              align-items: center;

              .pagination_item {
                height: 30px;
                :deep(button) {
                  height: 30px;
                }
                :deep(ul li) {
                  height: 30px;
                }
              }
              .pagination_row_input_wrap {
                margin-left: 10px;
                .pagination_row_input_box {
                  margin-left: 10px;
                  margin-right: 10px;
                }
                // 这部分是可以自然设置number input部分样式(保留)
                // #region
                // .pageInput {
                //   &:deep(.el-input__inner) {
                //     height: 30px;
                //     line-height: 30px;
                //     box-sizing: border-box;
                //   }
                //   &:deep(.el-input-number__decrease) {
                //     height: 30px;
                //     line-height: 30px;
                //     box-sizing: border-box;
                //   }
                //   &:deep(.el-input-number__increase) {
                //     height: 30px;
                //     line-height: 30px;
                //     box-sizing: border-box;
                //   }
                // }
                // #endregion
              }

              .pagination_row_btn {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  .description_input {
    :deep(.el-textarea__inner) {
      height: 150px;
    }
  }

  .edit_equipment_dialog_box {
    .dialog_main {
      .equipment_img_wrap {
        display: flex;
        align-items: center;
        .old_equipment_img {
          height: 148px;
          margin-right: 8px;
          box-sizing: border-box;
          border: 1px solid var(--el-border-color);
          border-radius: 6px;
        }
        .upload-product-photo {
          :deep(.el-upload-list__item) {
            height: 148px;
            width: 221px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.equipmentList_wrap {
  .el-dialog {
    .el-dialog__header {
      height: 50px;
      .el-dialog__headerbtn {
        .el-icon.el-dialog__close {
          font-size: 16px;
        }
      }
    }
    .el-dialog__body {
      text-align: center;
      .preview_img {
        width: 600px;
      }
    }
  }
}
</style>
